import { useDebounce, useFormatMessage } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { DatePicker } from 'antd';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { CustomTimePicker } from 'components/time_picker';
import moment from 'moment';
import {
  countryList,
  dietaries,
  fetchSuburb,
  restaurantCountries,
  restaurantFoodStyles,
  street_abbreviation,
  subscriptions,
  timezones,
  // validateABN,
  validateEmail,
} from 'utils';
import PhoneInput from 'react-phone-input-2';
import Dropdown from 'components/Dropdown';
import './restaurantform.css';
import firebase from 'firebase.js';
import CheckboxSelect from 'components/CheckboxSelect';
import RadioButtonSelect from 'components/RadioButtonSelect';
import ConfirmationModal from 'components/ConfirmationModal';
import {
  SquarePaymentsForm,
  CreditCardInput,
  ErrorScreen
} from 'react-square-web-payments-sdk';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { CCData } from './creditcard_info';

export const RestaurantForm = (props) => {
  const {
    handleSubmit,
    control,
    register,
    errors,
    reset,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: props.restaurant,
  });

  const postcodeValue = watch('address.postcode');
  const debouncedPostcode = useDebounce(postcodeValue, 400);
  const [suburbs, setSuburbs] = useState([]);
  const pos = watch('pos_provider');
  const twentyFourHours = watch('is_24_hrs');
  const street = watch('address.street_name');

  const { fields, append } = useFieldArray({
    control,
    name: 'working_hours',
  });

  const fileRef = useRef(null);
  const [file, setFile] = useState({});
  const [showCard, setShowCard] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [showCardDeleteConfirm, setShowCardDeleteConfirm] = useState(false);

  // useEffect(() => {
  //   const search = async () => {
  //     if (debouncedPostcode) {
  //       const res = await fetchSuburb(debouncedPostcode);
  //       if (res) {
  //         setSuburbs(res);
  //       } else {
  //         setSuburbs([]);
  //       }
  //     }
  //   };
  //   //search();
  // }, [debouncedPostcode]);

  useEffect(() => {
    if (street) {
      let newStreet = [...street.split(' ')];
      street
        .toLowerCase()
        .split(' ')
        .forEach((el, replacingAt) => {
          if (
            Object.values(street_abbreviation)
              .map((el) => el.toLowerCase())
              .includes(el)
          ) {
            const index = Object.values(street_abbreviation)
              .map((el) => el.toLowerCase())
              .findIndex((word) => word.includes(el));
            const abbreviation = Object.keys(street_abbreviation)[index];
            newStreet[replacingAt] = abbreviation;
          }
        });

      newStreet = newStreet.join(' ');

      setValue('address.street_name', newStreet);
    }
  }, [street]);

  useEffect(() => {
    const fetchImage = async () => {
      setFile({ image: 'loading' });
      if (props.restaurant.image) {
        const meta = await firebase.storage()
          .refFromURL(props.restaurant.image)
          .getMetadata();

        setFile({
          image: {
            name: meta.customMetadata && meta.customMetadata.name,
          },
          previewImage: props.restaurant.image,
        });
      } else {
        setFile({});
      }
    };
    let defaultValues;
    if (props.restaurant.categories) {
      defaultValues = Object.assign({}, props.restaurant);
      defaultValues.categoriesCountry = defaultValues.categories.filter((el) =>
        restaurantCountries.includes(el)
      );

      defaultValues.categoriesFoodStyle = defaultValues.categories.filter(
        (el) => restaurantFoodStyles.includes(el)
      );

      fetchImage();
    } else {
      setFile({});
    }

    if (props.restaurant.subscription_cc) {
      setCardData(props.restaurant.subscription_cc);
    }

    reset(defaultValues);

    if (fields.length === 0) {
      if (Object.keys(props.restaurant).length > 0) {
        append(props.restaurant.working_hours);
      }
    }
  }, [props.restaurant, append]);

  const deleteCCSubscriptionConfirm = () => {
    setShowCardDeleteConfirm(true);
  };

  const deleteCCSubscription = () => {
    // Get cardData
    //if cardData is not null call setCardData and assign null
    // call setShowCard with false

    if(cardData !== null){
      setCardData(null);
      setShowCard(false);
    }

    setShowCardDeleteConfirm(false);
  };

  const onSubmit = async (data) => {
    if (data.address.suburb) {
      if (suburbs.length !== 0) {
        data.address.state = suburbs.find(
          (el) => el.name === data.address.suburb
        ).state.abbreviation;
      }
    }
    let abnIsValid = true; //await validateABN(data.business_name);

    //Set subscription cc details
    data.subscription_cc = cardData;

    //Set isActive based on props restaurant data as we are not dealing with that value in the form

    data.is_active = (typeof props.restaurant.is_active === 'undefined' || props.restaurant.is_active === null) ? true : props.restaurant.is_active;
    console.log(new Date(props.restaurant.time_joined_posix));
    if (abnIsValid) {
      props.onSubmit(data);
    } else {
      setError('abn', {
        type: 'manual',
        message: 'abn error',
      });
    }
  };

  const errorName = useFormatMessage('Restaurant.errorName');
  const errorOwnerName = useFormatMessage('Restaurant.errorOwnerName');
  const errorEmail = useFormatMessage('Restaurant.errorEmail');
  const errorPhone = useFormatMessage('Restaurant.errorPhone');
  const errorStreetName = useFormatMessage('Restaurant.errorStreetName');
  const errorBuildingNumber = useFormatMessage(
    'Restaurant.errorBuildingNumber'
  );
  const country = useFormatMessage('Restaurant.country');
  const errorAbnInvalid = useFormatMessage('Restaurant.errorAbnInvalid');
  const errorCity = useFormatMessage('Restaurant.errorCity');

  const errorRequired = useFormatMessage('Restaurant.requiredFieldMessage');
  const suburb = useFormatMessage('Restaurant.suburb');
  const city = useFormatMessage('Restaurant.city');
  const streetName = useFormatMessage('Restaurant.streetName');
  const buildingNumber = useFormatMessage('Restaurant.buildingNumber');
  const unitNumber = useFormatMessage('Restaurant.unitNumber');

  const chooseFile = useFormatMessage('Restaurant.chooseFile');
  const fileLabel = useFormatMessage('Restaurant.fileLabel');
  const errorCountry = useFormatMessage('Restaurant.errorCountry');
  const submit = useFormatMessage('Restaurant.submit');
  const hours = useFormatMessage('Restaurant.24Hrs');
  const displayName = useFormatMessage('Restaurant.displayName');
  const accountName = useFormatMessage('Restaurant.accountName');
  const email = useFormatMessage('Restaurant.email');
  const workingHours = useFormatMessage('Restaurant.tradingHours');
  const subscriptionLbl = useFormatMessage('Restaurant.subscription');
  const phone = useFormatMessage('Restaurant.phone');
  const businessName = useFormatMessage('Restaurant.businessName');
  const ABN = useFormatMessage('Restaurant.ABN');
  const address = useFormatMessage('Restaurant.address');
  const coordinates = useFormatMessage('Restaurant.coordinates');
  const plInsurance = useFormatMessage('Restaurant.plInsurance');
  const contactOfOwner = useFormatMessage('Restaurant.contactOfOwner');
  const ownerName = useFormatMessage('Restaurant.ownerName');
  const timezone = useFormatMessage('Restaurant.timezone');
  const bankAccount = useFormatMessage('Restaurant.bankAccount');
  const accountNumber = useFormatMessage('Restaurant.AccountNumber');
  const bankAccountBSB = useFormatMessage('Restaurant.bankAccountBSB');
  const nameInBankAccount = useFormatMessage('Restaurant.nameInBankAccount');
  const dietary = useFormatMessage('Restaurant.dietary');
  const countryCategories = useFormatMessage('Restaurant.countryCategories');
  const foodStyles = useFormatMessage('Restaurant.foodStyles');
  const postcode = useFormatMessage('Restaurant.postcode');
  const posProvider = useFormatMessage('Restaurant.posProvider');
  const posLocationId = useFormatMessage('Restaurant.posLocationId');
  const signUpDate = useFormatMessage('Restaurant.signUpDate');
  const days = [
    useFormatMessage('Restaurant.Sunday'),
    useFormatMessage('Restaurant.Monday'),
    useFormatMessage('Restaurant.Tuesday'),
    useFormatMessage('Restaurant.Wednesday'),
    useFormatMessage('Restaurant.Thursday'),
    useFormatMessage('Restaurant.Friday'),
    useFormatMessage('Restaurant.Saturday'),
  ];

  const handleFileSelect = () => {
    if (fileRef.current.files) {
      setFile({
        image: fileRef.current.files[0],
        previewImage: URL.createObjectURL(fileRef.current.files[0]),
      });
      props.setSelectedFile(fileRef.current.files[0]);
    }
  };

  const renderSubscription = () => {
    return (
      <div className="field">
        <label className="label">{subscriptionLbl}</label>
        <div className="control">
          <Controller
            rules={{
              required: true,
              validate: (value) => value.length > 0 || 'Empty',
            }}
            control={control}
            name="subscription"
            render={({ onChange, value }) => (
              <RadioButtonSelect
                error={errors.dietary}
                data={subscriptions}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>
    );
  };

  const renderCreditCardCharge = () => {
    let cardUniqueId = _.uniqueId("cc-");
    return (
      <div className="field">
        {!showCard && (<button
          className={`button is-fullwidth is-link`}
          onClick={(e) => {
            setShowCard(true)
          }}
        >
          Add Credit Card
        </button>)}
        {showCard && (
          <div>
            <div id={cardUniqueId}></div>
            <SquarePaymentsForm
              /**
               * Identifies the calling form with a verified application ID
               * generated from the Square Application Dashboard.
               */
              applicationId="sandbox-sq0idb-7KE3zXHZLG_X5EmLLptTYw"
              /**
               * Invoked when payment form receives the result of a tokenize generation request.
               * The result will be a valid credit card or wallet token, or an error.
               */
              cardTokenizeResponseReceived={(tokenRes, buyer) => {
                let clonedRes = Object.assign({}, tokenRes);
                console.info({ clonedRes, buyer });

                //Set token to state
                if (clonedRes.status === "OK")
                  setCardData({ token: clonedRes.token, card: clonedRes.details.card });
              }}
              /**
               * This function enable the Strong Customer Authentication (SCA) flow
               *
               * We strongly recommend use this function to verify the buyer and
               * reduce the chance of fraudulent transactions.
               */

              /**
               * Identifies the location of the merchant that is taking the payment.
               * Obtained from the Square Application Dashboard - Locations tab.
               */
              locationId="4P550BZQ0TQZA"
              formId={cardUniqueId}
            >
              <CreditCardInput text="Verify" cardContainerId={cardUniqueId} />
            </SquarePaymentsForm>
          </div>
        )}
      </div>
    );
  };

  const renderWorkingHours = () => {
    return fields.map((field, index) => {
      return (
        <tr key={field.id}>
          <td>
            <label className="label">{days[index]}</label>
          </td>

          <td>
            <Controller
              as={<CustomTimePicker disabled={twentyFourHours} />}
              name={`working_hours[${index}].start_time`}
              control={control}
              rules={{ required: true }}
              defaultValue={
                props.restaurant.working_hours &&
                moment(
                  decimalToHHmm(
                    props.restaurant.working_hours[index].start_time
                  ),
                  'HH:mm'
                )
              }
            />
          </td>

          <td>
            <Controller
              as={<CustomTimePicker disabled={twentyFourHours} />}
              name={`working_hours[${index}].end_time`}
              control={control}
              rules={{ required: true }}
              defaultValue={
                props.restaurant.working_hours &&
                moment(
                  decimalToHHmm(props.restaurant.working_hours[index].end_time),
                  'HH:mm'
                )
              }
            />
          </td>
          <td>
            <Controller
              as={<div>
                <input
                  type="checkbox"
                  className="switch is-rounded"
                  value={props.restaurant.working_hours[index].closed}
                />
              </div>
              }
              name={`working_hours[${index}].closed`}
              control={control}
              defaultValue={
                (props.restaurant.working_hours &&
                  props.restaurant.working_hours[index].closed)
              }
            />
          </td>
        </tr>
      );
    });
  };

  const decimalToHHmm = (input) => {
    return (
      ('0' + (Math.floor(input) % 24)).slice(-2) +
      ':' +
      ((input % 1) * 60 + '0').slice(0, 2)
    );
  };

  return (
    <div>
      <div className={`modal ${props.isActive ? 'is-active' : ''}`}>
        <div className="modal-background" ></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{props.title}</p>
            <div className="field" style={{ marginRight: '10px' }}>
              <button className="button" onClick={handleSubmit(onSubmit)}>
                {submit}
              </button>
            </div>
            <button
              className="delete"
              aria-label="close"
              onClick={props.closeModal}
            />
          </header>
          <section className="modal-card-body">
            <div className="container">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <label className="label">{posProvider}</label>
                  <div className="control is-expanded select">
                    <select
                      ref={register({ required: true })}
                      name="pos_provider"
                    >
                      <option>None</option>
                      <option>Doshii</option>
                      <option>Square</option>
                      <option>Provided a Tablet</option>
                    </select>
                  </div>
                </div>
                {(pos === 'Doshii' || pos === 'Square') && (
                  <div className="field">
                    <div className="control is-expanded">
                      <input
                        className="input"
                        placeholder={posLocationId}
                        name="pos_location_id"
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>
                )}
                <div className="field">
                  <label className="label">{businessName}</label>
                  <div className="control">
                    <input
                      className={`input ${errors.business_name ? 'is-danger' : ''
                        }`}
                      name="business_name"
                      ref={register({ required: true })}
                    />
                    <div
                      className={`help ${errors.business_name ? 'is-danger' : ''
                        }`}
                    >
                      {errors.business_name && errorRequired}
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label">{ABN}</label>
                  <div className="control">
                    <input
                      className={`input ${errors.abn ? 'is-danger' : ''}`}
                      name="abn"
                      ref={register({ required: true })}
                    />
                    <div className={`help ${errors.abn ? 'is-danger' : ''}`}>
                      {errors.abn &&
                        (errors.abn.message ? errorAbnInvalid : errorRequired)}
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label">{displayName}</label>
                  <div className="control">
                    <input
                      className={`input ${errors.name ? 'is-danger' : ''}`}
                      name="name"
                      ref={register({
                        required: 'Required',
                      })}
                    />
                    <div className={`help ${errors.name ? 'is-danger' : ''}`}>
                      {errors.name && errorName}
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">{ownerName}</label>
                  <div className="control">
                    <input
                      className={`input ${errors.owner_name ? 'is-danger' : ''}`}
                      name="owner_name"
                      ref={register({
                        required: 'Required',
                      })}
                    />
                    <div className={`help ${errors.owner_name ? 'is-danger' : ''}`}>
                      {errors.owner_name && errorOwnerName}
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">{email}</label>
                  <div className="control">
                    <input
                      className={`input ${errors.email ? 'is-danger' : ''}`}
                      name="email"
                      type="email"
                      ref={register({
                        required: 'Required',
                        validate: (value) =>
                          validateEmail(value) !== null &&
                          validateEmail(value).length > 0,
                      })}
                    />
                    <div className={`help ${errors.email ? 'is-danger' : ''}`}>
                      {errors.email && errorEmail}
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label">{phone}</label>
                  <Controller
                    name="contact_phone"
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value }) => (
                      <PhoneInput
                        inputClass="input"
                        specialLabel=""
                        country={'au'}
                        onChange={(value, data, event, formattedValue) =>
                          onChange(formattedValue)
                        }
                        preferredCountries={['au']}
                        value={value}
                      />
                    )}
                  />
                  <div
                    className={`help ${errors.contact_phone ? 'is-danger' : ''
                      }`}
                  >
                    {errors.contact_phone && errorPhone}
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">{address}</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          placeholder={unitNumber}
                          className="input"
                          name="address.unit_number"
                          ref={register()}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <input
                          placeholder={buildingNumber}
                          className={`input ${errors.address && errors.address.building_number
                            ? 'is-danger'
                            : ''
                            }`}
                          name="address.building_number"
                          ref={register({
                            required: 'Required',
                          })}
                        />
                      </div>
                      <div
                        className={`help ${errors.address && errors.address.building_number
                          ? 'is-danger'
                          : ''
                          }`}
                      >
                        {errors.address &&
                          errors.address.building_number &&
                          errorBuildingNumber}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label"></div>
                  <div className="field-body">
                    <div className="field is-expanded">
                      <div className="control">
                        <input
                          placeholder={streetName}
                          className={`input ${errors.address && errors.address.street_name
                            ? 'is-danger'
                            : ''
                            }`}
                          name="address.street_name"
                          ref={register({
                            required: 'Required',
                          })}
                        />
                      </div>

                      <div
                        className={`help ${errors.address && errors.address.street_name
                          ? 'is-danger'
                          : ''
                          }`}
                      >
                        {errors.address &&
                          errors.address.street_name &&
                          errorStreetName}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label"></div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control is-expanded">
                        <input
                          placeholder={suburb}
                          className={`input ${errors.address && errors.address.suburb
                            ? 'is-danger'
                            : ''
                            }`}
                          name="address.suburb"
                          ref={register({
                            required: 'Required',
                          })}
                        />
                        <div
                          className={`help ${errors.address && errors.address.suburb
                            ? 'is-danger'
                            : ''
                            }`}
                        >
                          {errors.address &&
                            errors.address.suburb &&
                            errorRequired}
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control is-expanded">
                        <input
                          placeholder={postcode}
                          className={`input ${errors.address && errors.address.postcode
                            ? 'is-danger'
                            : ''
                            }`}
                          name="address.postcode"
                          ref={register({
                            required: 'Required',
                          })}
                        />
                      </div>
                      <div
                        className={`help ${errors.address && errors.address.postcode
                          ? 'is-danger'
                          : ''
                          }`}
                      >
                        {errors.address &&
                          errors.address.postcode &&
                          errorRequired}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal"></div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control is-expanded">
                        <input
                          placeholder={city}
                          className={`input ${errors.address && errors.address.city
                            ? 'is-danger'
                            : ''
                            }`}
                          name="address.city"
                          ref={register({
                            required: 'Required',
                          })}
                        />
                      </div>

                      <div
                        className={`help ${errors.address && errors.address.city
                          ? 'is-danger'
                          : ''
                          }`}
                      >
                        {errors.address && errors.address.city && errorCity}
                      </div>
                    </div>
                    <div className="field">
                      <div className="control is-expanded">
                        <Controller
                          name="address.Country"
                          control={control}
                          rules={{
                            required: true,
                            validate: (value) => value !== country,
                          }}
                          defaultValue={
                            (props.restaurant &&
                              props.restaurant.address &&
                              props.restaurant.address.Country) ||
                            country
                          }
                          render={({ onChange, value }) => (
                            <Dropdown
                              onChange={onChange}
                              value={value}
                              placeholder={country}
                              data={countryList}
                              error={errors.address && errors.address.Country}
                            />
                          )}
                        />
                        <div
                          className={`help ${errors.address && errors.address.Country
                            ? 'is-danger'
                            : ''
                            }`}
                        >
                          {errors.address &&
                            errors.address.Country &&
                            errorCountry}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <label className="label">{workingHours}</label>
                  <table className="table is-narrow">
                    <tbody>{renderWorkingHours()}</tbody>
                  </table>
                </div>
                {props.isAdmin && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">{coordinates}</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            placeholder="Latitude"
                            className={`input ${errors.location && errors.location.lat
                              ? 'is-danger'
                              : ''
                              }`}
                            name="location.lat"
                            ref={register({
                              required: 'Required',
                              pattern: /^-?[0-9][0-9,\.]+$/i,
                            })}
                          />
                          <div
                            className={`help ${errors.location && errors.location.lat
                              ? 'is-danger'
                              : ''
                              }`}
                          >
                            {errors.location &&
                              errors.location.lat &&
                              errorRequired}
                          </div>
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <input
                            placeholder="Longitude"
                            className={`input ${errors.location && errors.location.lng
                              ? 'is-danger'
                              : ''
                              }`}
                            name="location.lng"
                            ref={register({
                              required: 'Required',
                              pattern: /^-?[0-9][0-9,\.]+$/i,
                            })}
                          />
                          <div
                            className={`help ${errors.location && errors.location.lng
                              ? 'is-danger'
                              : ''
                              }`}
                          >
                            {errors.location &&
                              errors.location.lng &&
                              errorRequired}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {props.isAdmin && (
                  <div className="field">
                    <label className="label">{timezone}</label>
                    <Controller
                      name="timezone"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      defaultValue={
                        props.restaurant?.timezone ||
                        'Universal Coordinated Time'
                      }
                      render={({ onChange, value }) => (
                        <Dropdown
                          onChange={onChange}
                          value={value}
                          width="100%"
                          data={timezones}
                          error={errors.timezone}
                        />
                      )}
                    />
                  </div>
                )}
                {props.isAdmin && (
                  <div className="field">
                    <label className="label">{signUpDate}</label>
                    <div className="control">
                      <Controller
                        control={control}
                        name="time_joined_posix"
                        rules={{
                          required: true,
                        }}
                        render={({ onChange, value }) => (
                          <DatePicker
                            onChange={onChange}
                            value={(value) ? moment(value) : moment()}
                            defaultValue={moment()}
                            width="100%"
                            error={errors.time_joined_posix}
                          />
                        )}
                      />
                      <div
                        className={`help ${errors.time_joined_posix ? 'is-danger' : ''
                          }`}
                      >
                        {errors.time_joined_posix && errorRequired}
                      </div>
                    </div>
                  </div>
                )}
                <div className="field">
                  <label className="label">{contactOfOwner}</label>
                  <div className="control">
                    <input
                      className={`input ${errors.owner_contact ? 'is-danger' : ''
                        }`}
                      name="owner_contact"
                      ref={register({ required: true })}
                    />
                    <div
                      className={`help ${errors.owner_contact ? 'is-danger' : ''
                        }`}
                    >
                      {errors.owner_contact && errorRequired}
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label">{plInsurance}</label>
                  <div className="control">
                    <input
                      className={`input ${errors.pl_insurance ? 'is-danger' : ''
                        }`}
                      name="pl_insurance"
                      ref={register({ required: true })}
                    />
                    <div
                      className={`help ${errors.pl_insurance ? 'is-danger' : ''
                        }`}
                    >
                      {errors.pl_insurance && errorRequired}
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label">{accountName}</label>
                  <div className="control">
                    <input
                      placeholder={nameInBankAccount}
                      className={`input ${errors.account_name ? 'is-danger' : ''
                        }`}
                      name="account_name"
                      ref={register({ required: true })}
                    />
                    <div
                      className={`help ${errors.account_name ? 'is-danger' : ''
                        }`}
                    >
                      {errors.account_name && errorRequired}
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal is-one-fifths">
                    <label className="label">{bankAccount}</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-one-fifth">
                      <div className="control">
                        <input
                          placeholder={bankAccountBSB}
                          className={`input ${errors.bank_bsb ? 'is-danger' : ''
                            }`}
                          name="bank_bsb"
                          ref={register({
                            required: 'Required',
                          })}
                        />
                        <div
                          className={`help ${errors.bank_bsb ? 'is-danger' : ''
                            }`}
                        >
                          {errors.bank_bsb && errorRequired}
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <input
                          placeholder={accountNumber}
                          className={`input ${errors.bank_account ? 'is-danger' : ''
                            }`}
                          name="bank_account"
                          ref={register({
                            required: true,
                          })}
                        />
                        <div
                          className={`help ${errors.bank_account ? 'is-danger' : ''
                            }`}
                        >
                          {errors.bank_account && errorRequired}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <label className="label">{dietary}</label>
                      <div className="control">
                        <Controller
                          rules={{
                            required: true,
                            validate: (value) => value.length > 0 || 'Empty',
                          }}
                          control={control}
                          name="dietary"
                          render={({ onChange, value }) => (
                            <CheckboxSelect
                              error={errors.dietary}
                              data={dietaries}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">{foodStyles}</label>
                      <div className="control">
                        <Controller
                          rules={{
                            required: true,
                            validate: (value) => value.length > 0 || 'Empty',
                          }}
                          control={control}
                          name="categoriesFoodStyle"
                          render={({ onChange, value }) => (
                            <CheckboxSelect
                              error={errors.categoriesFoodStyle}
                              data={restaurantFoodStyles}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">{countryCategories}</label>
                      <div className="control">
                        <Controller
                          rules={{
                            required: true,
                            validate: (value) => value.length > 0 || 'Empty',
                          }}
                          control={control}
                          name="categoriesCountry"
                          render={({ onChange, value }) => (
                            <CheckboxSelect
                              error={errors.categoriesCountry}
                              data={restaurantCountries}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box">
                  {renderSubscription()}
                  {
                    (typeof props.restaurant.id === 'undefined' || typeof props.restaurant.subscription_cc === 'undefined' || props.restaurant.subscription_cc === null) && cardData === null && (
                      renderCreditCardCharge()
                    )
                  }
                  {(props.restaurant.subscription_cc || cardData) && (
                    <CCData cardData={props.restaurant.subscription_cc || cardData} ccDeleteCallback={deleteCCSubscriptionConfirm} />
                  )}
                </div>

                <div className="field">
                  <label className="label">{fileLabel}</label>
                  <div
                    className={`control ${file && file.image === 'loading' ? 'is-loading' : ''
                      }`}
                  >
                    <div className="file has-name is-fullwidth">
                      <label className="file-label">
                        <input
                          className="file-input"
                          type="file"
                          ref={fileRef}
                          accept="image/*"
                          onChange={handleFileSelect}
                        />
                        {file != 'loading' && (
                          <img
                            className="preview-img"
                            src={file.previewImage}
                          />
                        )}
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="mdi mdi-file-upload"></i>
                          </span>
                          <span className="file-label">{chooseFile}</span>
                        </span>
                        <span className="file-name">
                          {file.image && file.image.name}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="field is-grouped is-grouped-right">
                  <button
                    className={`button ${Object.keys(errors).length > 0
                      ? 'is-danger is-outlined is-inverted is-focused'
                      : ''
                      }`}
                    type="submit"
                  >
                    {submit}
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
      <ConfirmationModal
        isActive={showCardDeleteConfirm}
        isLoading={false}
        title={useFormatMessage('CCSubscribe.confirmTitle')}
        body={useFormatMessage('CCSubscribe.confirmDelete')}
        confirmButtonMessage={useFormatMessage('Promotions.delete')}
        onConfirmation={deleteCCSubscription}
        cancelButtonMessage={useFormatMessage('Aside.cancel')}
        onCancel={() => setShowCardDeleteConfirm(false)}
      />
    </div>
  );
};

export default RestaurantForm;
